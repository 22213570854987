/* import __COLOCATED_TEMPLATE__ from './away-status-reasons-section.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import type AwayStatusReason from 'embercom/models/away-status-reason';

interface Args {
  awayStatusReasons: Array<AwayStatusReason>;
}

interface Signature {
  Element: never;
  Args: Args;
}

export default class AwayStatusReasonsSection extends Component<Signature> {
  @service declare session: any;
  @tracked awayReasonsEditorVisible = false;
  @tracked mandatory = false;

  get mandatorySwitchVisible() {
    return false;
  }

  @action
  openAwayReasonsEditor() {
    this.awayReasonsEditorVisible = true;
  }

  @action
  closeAwayReasonsEditor() {
    this.awayReasonsEditorVisible = false;
  }

  @action
  toggleMandatory() {
    this.mandatory = !this.mandatory;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::TabsComponents::AwayStatusReasonsSection': typeof AwayStatusReasonsSection;
  }
}
